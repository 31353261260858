export const AppTheme = {
    token: {
        colorPrimary: '#287ee6',
        colorLink: '#2a9ae0',
        // colorLink: 'rgb(53,58,216)',
         colorLinkHover: '#51bcf8'
        // colorLink: 'rgb(42, 8, 255)',
        // colorLinkHover: 'rgb(78,92,255)'
    }
};
